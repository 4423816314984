<template>
    <b-card>
        <custom-loader :show="showLoader">
            <v-select v-if="editing" v-model="selected" @input="setSelection" appendToBody class="floating-filter w-100" :options="versions" :clearable="false" :searchable="false" label="id" :reduce="item => item">
                <template #selected-option="{id,version_timestamp}">
                    <span  class="textCutSelect">
                        {{$t('version.current')}} - {{0 > getIndexById(id)  ? versions.length === 0 ? versions.length : versions.length-1  : versions.length - getIndexById(id)-1}}
                        ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                    </span>
                </template>
                <template #option="{id,version_timestamp}">
                    <span  class="textCutSelect">
                        {{$t('version.version')}} - {{versions.length - getIndexById(id)-1}}
                        ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                    </span>
                </template>
            </v-select>

            <div v-if="!showLoader">
                <div class="mb-1 w-100 d-flex justify-content-end mt-3" v-if="stockTaking && editing">
                    <b-button variant="primary" class="mr-2" @click="viewReportModalDiff">
                        {{ $t('material.stock_taking.view_stock_difference') }}
                    </b-button>
                    <b-button variant="primary" @click="viewReportModal">
                        {{ $t('general.view_printout') }}
                    </b-button>
                </div>
                <div class="col-12 col-md-6 p-0">
                    <div class="data-border">
                        <div class="border-text">
                            {{ $t('material.stock_taking.stock_taking_data') }}
                        </div>

                        <div class="p-1">
                            <validation-observer ref="validation">
                                <!-- SKLADISCE -->
                                <div class="col-12">
                                    <b-form-group :label="$t('general.warehouse')">
                                        <div v-if="addObject && stockTaking && editing && addObject.warehouse_id && stockTaking.warehouse_id && addObject.warehouse_id !== stockTaking.warehouse_id" class="pb-1" style="text-decoration: line-through">
                                            <v-select v-model="stockTaking.warehouse_id" :disabled="true" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id"/>
                                        </div>

                                        <validation-provider #default="{ errors }" :name="$t('general.warehouse')" rules="required">
                                            <v-select v-model="addObject.warehouse_id" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id" @input="updateTemporaryFileStockTakingData"/>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <!-- DATUM -->
                                <div class="col-12">
                                    <b-form-group :label="$t('general.date')">
                                        <validation-provider #default="{ errors }" :name="$t('general.date')" rules="required">
                                            <div v-if="addObject && stockTaking && editing && formatDate(addObject.timestamp) !== formatDate(stockTaking.timestamp)" class="pb-1" style="text-decoration: line-through">
                                                <input class="form-control"
                                                       :value="formatDate(stockTaking.timestamp)"
                                                       :disabled="true"
                                                />
                                            </div>

                                            <date-picker :first-day-of-week="2" v-model="addObject.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" is24hr hide-time-header :is-required="true" @input="updateTemporaryFileStockTakingData">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="formatDate(inputValue)"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                            </validation-observer>

                            <div v-if="stockTaking && editing && differenceInData" class="d-flex p-1 justify-content-end">
                                <b-button variant="warning" @click="revertStockTakingData">{{ $t('general.revert') }}</b-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!infoProvided" class="d-flex p-2 justify-content-end">
                    <b-button variant="primary" @click="validate">
                        <span class="align-middle">{{ $t('general.next_step') }}</span>
                    </b-button>
                </div>

                <div v-if="loaded && infoProvided" :title="$t('menu.subcategory.stock_taking')">
                    <div class="mt-3 mb-3" style="font-size: 1.285rem; font-weight: 500;">
                        {{$t("menu.subcategory.stock_taking")}}
                    </div>
                    <Table ref="stockEditingTable" class="mt-1" :columnDefs="columnDefs" :rowData="ingredients" :showRedForDisabled="false" @edit="rowValueChanged"/>
                    <div class="row mt-2" v-if="stepNum === 2 && togetherPrice">
                        <div class="col-12 col-md-6 pr-md-0">
                            <div class="data-border">
                                <div class="border-text bgCard">
                                    {{$t("material.stock_taking.final_state_of_stock_taking")}}
                                </div>

                                <!-- CONTENT -->
                                <div class="row p-2">

                                    <div class="col-6 text-price">
                                        {{ $t("material.stock_taking.surplus") }}:
                                    </div>
                                    <div class="col-6 mb-1 text-price-bold text-right">
                                        {{$numberRounding(togetherPrice.difference_plus)}}€
                                    </div>

                                    <div class="col-6 text-price">
                                        {{ $t('material.stock_taking.deficit') }}:
                                    </div>
                                    <div class="col-6 mb-1 text-price-bold text-right">
                                        {{$numberRounding(togetherPrice.difference_minus)}}€
                                    </div>

                                    <div class="col-6 text-price">
                                        {{ $t('material.stock_taking.final_state')}}:
                                    </div>
                                    <div class="col-6 text-price-bold text-right">
                                        {{$numberRounding(togetherPrice.difference_together)}}€
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="d-flex justify-content-between mt-2">
                        <div>
                            <div class="d-flex">
                                <div class="pr-2">
                                    <b-button variant="danger" @click="deleteStockTaking">
                                        <span class="align-middle">{{ $t('general.delete') }}</span>
                                    </b-button>
                                </div>

                                <div v-if="stockTaking && editing" class="pr-2">
                                    <b-button variant="warning" @click="discardChangesStockTaking">
                                        <span class="align-middle">{{ $t('general.discard') }}</span>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <b-button v-if="stepNum === 1" variant="primary" @click="checkCurrentStock(true)">
                                    {{$t('general.next_step')}}
                                </b-button>
                                <b-button v-if="stepNum === 2" class="mr-2" variant="primary" @click="previousStep">
                                    {{$t('general.previous_step')}}
                                </b-button>
                            </div>
                            <b-button v-if="stepNum === 2" variant="success" @click="finishedStockTaking" :disabled="!allowConfirm">
                                <span v-if="stockTaking && editing" class="align-middle">{{ $t('general.accept') }}</span>
                                <span v-else class="align-middle">{{ $t('material.stock_taking.finish_stock_taking') }}</span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <report-preview-modal ref="stockTakingReportModal" />
            <report-preview-modal ref="stockTakingReportModalDifferenceInStock" />

        </custom-loader>
    </b-card>
</template>

<script>
    import {BButton, BFormGroup, BCard} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import vSelect from 'vue-select'
    import {DatePicker} from 'v-calendar'
    import { required } from '@validations'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import Table from '@/views/components/Table/Table.vue'
    import CustomLoader from '@/components/CustomLoader.vue'

    export default {
        components: {
            CustomLoader,
            Table,
            ReportPreviewModal,
            BFormGroup,
            vSelect,
            DatePicker,
            ValidationProvider,
            ValidationObserver,
            BButton,
            BCard
        },
        computed: {
            differenceInData() {
                if (!this.stockTaking || !this.addObject) {
                    return false
                }

                return !(this.addObject.warehouse_id === this.stockTaking.warehouse_id &&
                    this.formatDate(this.addObject.timestamp) === this.formatDate(this.stockTaking.timestamp))
            },
            compareData() {
                if (!this.stockTaking || !this.addObject) {
                    return false
                }

                return this.compareItemsArray(this.stockTaking.items, this.addObject.items) && this.addObject.warehouse_id === this.stockTaking.warehouse_id
            },

            joinedTable() {
                const resultArray = []

                const addObjectMap = new Map(this.addObject.items.map(item => [item.ingredient_id, item]))


                for (const stockTakingItem of this.stockTaking.items) {
                    const addObjectItem = addObjectMap.get(stockTakingItem.ingredient_id)

                    if (addObjectItem) {
                        if (this.objectsDiffer(stockTakingItem, addObjectItem)) {
                            resultArray.push({ ...addObjectItem, temporary_changes: 2 })
                        } else {
                            resultArray.push({ ...addObjectItem, temporary_changes: 3 })
                        }
                    } else {
                        resultArray.push({ ...stockTakingItem, temporary_changes: 1 })
                    }
                }

                if (this.addObject.items.length !== 0) {
                    for (const addObjectItem of this.addObject.items) {

                        const existingIndex = resultArray.findIndex(item => item.ingredient_id === addObjectItem.ingredient_id)

                        if (existingIndex === -1) {
                            resultArray.push({ ...addObjectItem, temporary_changes: 0 })
                        }
                    }
                }

                resultArray.sort((a, b) => a.ingredient_id - b.ingredient_id)

                return resultArray.length ? resultArray : []
            },
            togetherPrice() {
                if (!this.currentStock || !this.currentStock.ingredients || this.currentStock.ingredients.length <= 0) {
                    return null
                }

                const items = this.joinedTable

                let differencePlus = 0
                let differenceMinus = 0

                for (const item of items) {
                    const current_stock = this.currentStock.ingredients.find(ele => ele.ingredient_id === item.ingredient_id)

                    if (!current_stock) {
                        continue
                    }

                    const diffInStock = item.stock - current_stock.total_stock

                    const price = current_stock.average_price * diffInStock

                    if (price >= 0) {
                        differencePlus += price
                    } else {
                        differenceMinus += price
                    }
                }

                return {
                    'difference_minus': differenceMinus,
                    'difference_plus': differencePlus,
                    'difference_together': differencePlus + differenceMinus
                }
            },
            money_types() {
                return this.$store.getters['ingredients/moneyTypes']
            },
            weight_types() {
                return this.$store.getters['ingredients/weightTypes']
            },
            volume_types() {
                return this.$store.getters['ingredients/volumeTypes']
            },
            piece_types() {
                return this.$store.getters['ingredients/pieceTypes']
            }
        },
        data() {
            return {
                editing:false,
                ingredient_categories: [],
                ingredients: [],
                loaded: false,
                apiUrl: '',
                fileName: '',
                apiUrlDiff: '',
                fileNameDiff: '',
                addObject: {
                    warehouse_id: '',
                    timestamp: new Date().toISOString(),
                    items: []
                },
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                warehouses: [],
                showLoader: false,
                stockTaking: null,
                infoProvided: false,
                items: [],
                stepNum: 1,
                columnDefs: [
                    { headerName: this.$t('table_fields.code'), field: 'ingredient_code', editable: false, filter: true},
                    { headerName: this.$t('table_fields.name'), field: 'name', editable: false, filter: true},
                    { headerName: this.$t('table_fields.category'), field: 'ingredient_category_id', editable: false, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.ingredient_categories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.ingredient_categories} }, cellRenderer: (params) => this.getIngredientCategoryName(params.value)},
                    { headerName: this.$t('table_fields.quantity'), field: 'quantity', filter: true, cellRenderer: (params) => this.getQuantityValue(params.data), cellEditorFramework: 'packagingEditor' },
                    { headerName: this.$t('table_fields.packaging'), field: 'packaging', filter: true, cellRenderer: (params) => this.getPackagingValues(params.data), editable: false},
                    { headerName: this.$t('table_fields.stock'), field: 'stock', filter: true, cellRenderer: (params) => this.getPackagingNamesStock(params.data), cellEditorFramework: 'packagingEditor'},
                    { headerName: this.$t('table_fields.plus_stock'), field: 'sum_stock', filter: true, cellEditorFramework: 'PositiveNumberEditor'},
                    { headerName: this.$t('table_fields.plus_quantity'), field: 'sum_quantity', filter: true, cellEditorFramework: 'PositiveNumberEditor'}
                ],
                firstStepColumns : [
                    { headerName: this.$t('table_fields.code'), field: 'ingredient_code', editable: false, filter: true},
                    { headerName: this.$t('table_fields.name'), field: 'name', editable: false, filter: true},
                    { headerName: this.$t('table_fields.category'), field: 'ingredient_category_id', editable: false, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.ingredient_categories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.ingredient_categories} }, cellRenderer: (params) => this.getIngredientCategoryName(params.value)},
                    { headerName: this.$t('table_fields.quantity'), field: 'quantity', filter: true, cellRenderer: (params) => this.getQuantityValue(params.data)},
                    { headerName: this.$t('table_fields.packaging'), field: 'packaging', filter: true, cellRenderer: (params) => this.getPackagingValues(params.data), editable: false},
                    { headerName: this.$t('table_fields.stock'), field: 'stock', filter: true, cellRenderer: (params) => this.getPackagingNamesStock(params.data), cellEditorFramework: 'packagingEditor'},
                    { headerName: this.$t('table_fields.plus_stock'), field: 'sum_stock', filter: true},
                    { headerName: this.$t('table_fields.plus_quantity'), field: 'sum_quantity', filter: true}
                ],
                secondStepColumns : [
                    { headerName: this.$t('table_fields.code'), field: 'ingredient_code', editable: false, filter: true},
                    { headerName: this.$t('table_fields.name'), field: 'name', editable: false, filter: true},
                    { headerName: this.$t('table_fields.category'), field: 'ingredient_category_id', editable: false, filter: true, cellRenderer: (params) => this.getIngredientCategoryName(params.value)},
                    { headerName: this.$t('table_fields.quantity'), field: 'quantity', filter: true, editable: false, cellRenderer: (params) => this.getQuantityValue(params.data)},
                    { headerName: this.$t('table_fields.packaging'), field: 'packaging', filter: true, cellRenderer: (params) => this.getPackagingValues(params.data), editable: false},
                    { headerName: this.$t('table_fields.stock'), field: 'stock', filter: true, editable: false, cellRenderer: (params) => this.getPackagingNamesStock(params.data), cellEditorFramework: 'packagingEditor'},
                    { headerName: this.$t('table_fields.current_stock'), field: 'current_stock', filter: false, editable: false, cellRenderer: (params) => this.getCurrentStock(params.data) },
                    { headerName: this.$t('table_fields.stock_taking_value'), field: 'price_difference', filter: false, editable: false, cellRenderer: (params) => this.getDifferenceInPrice(params.data) },
                    { headerName: this.$t('table_fields.difference_in_stock'), field: 'difference_in_stock', filter: false, editable: false, cellRenderer: (params) => this.getDifferenceInStock(params.data) }
                ],
                required,
                versions:[],
                selected:{id:''},
                finished : false,
                allowConfirm : true,
                deleteConfirmed: false
            }
        },
        methods: {
            compareItemsArray(arr1, arr2) {
                if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
                if (arr1.length !== arr2.length) return false
                return arr1.every((item, index) => item === arr2[index])
            },
            formatDate(inputDate) {
                return this.dayjs(inputDate).format('dddd, DD.MM.YYYY')
            },
            getQuantityValue(data) {
                if (!data) return '/'

                const item = this.joinedTable.find(ele => ele.ingredient_id === data.id)

                if (!item) {
                    return '/'
                }

                return this.checkField(item, this.getQuantity)
            },
            getQuantity(data) {

                if (!data || data.stock === undefined || data.stock === null || data.stock === '') return '/'

                let type = null

                const item = this.ingredients.find(ele => ele.id === data.ingredient_id)

                if (!item) {
                    return '/'
                }

                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }

                if (!type) {
                    return
                }

                const allowedDecimals = Math.log10(type.factor)

                const stock = Number(Number(data.stock) / Number(item.packaging)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return `${stock  }x`
            },
            getPackagingNamesStock(data) {
                if (!data) return '/'

                const item = this.joinedTable.find(ele => ele.ingredient_id === data.id)

                if (!item) {
                    return '/'
                }

                return this.checkField(item, this.getStock)
            },
            getStock(data) {

                if (!data || data.stock === undefined || data.stock === null || data.stock === '') return '/'

                let type = null

                const item = this.ingredients.find(ele => ele.id === data.ingredient_id)

                if (!item) {
                    return '/'
                }

                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }

                if (!type) {
                    return
                }

                const allowedDecimals = Math.log10(type.factor)

                const stock = Number(Number(data.stock) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return `${stock  } ${  item.packaging_unit}`
            },
            getPackagingValues(item) {
                if (!item) return '/'

                let type = null
                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }

                if (!type) {
                    return
                }

                const allowedDecimals = Math.log10(type.factor)
                const packaging = Number(Number(item.packaging) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return `${packaging  } ${  item.packaging_unit}`
            },
            getIngredientCategoryName(value) {
                if (value) {
                    const item = this.ingredient_categories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            getCurrentStock(params) {
                if (!this.currentStock || !this.currentStock.ingredients || this.currentStock.ingredients.length <= 0) {
                    return '/'
                }

                const current_stock = this.currentStock.ingredients.find(ele => ele.ingredient_id === params.id)

                const payload = {
                    stock: current_stock.total_stock,
                    packaging_unit: current_stock.packaging_unit,
                    ingredient_id: params.id
                }

                return this.getStock(payload)
            },
            getDifferenceInPrice(params) {
                if (!this.currentStock || !this.currentStock.ingredients || this.currentStock.ingredients.length <= 0) {
                    return '/'
                }

                const current_stock = this.currentStock.ingredients.find(ele => ele.ingredient_id === params.id)

                const col = this.joinedTable.find(ele => ele.ingredient_id === params.id)

                if (!col) {
                    return '/'
                }

                let enteredStock = col.stock

                if (!enteredStock || isNaN(enteredStock)) {
                    enteredStock = current_stock.total_stock
                }

                const diffInStock =  enteredStock - current_stock.total_stock

                const price = current_stock.average_price * diffInStock
                let color = '#F54F29'

                if (price > 0) {
                    color = '#9C9B7A'
                } else if (price === 0) {
                    color = '#FFD393'
                }

                return ` <div style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center">
                               <div style="padding: 4px 0;  width: 100%; line-height: 20px; background: ${color}; border-radius: 20px; text-align: center;">
                                 ${this.$numberRounding(price)}€
                               </div>
                       </div>`
            },
            getDifferenceInStock(params) {
                if (!this.currentStock || !this.currentStock.ingredients || this.currentStock.ingredients.length <= 0) {
                    return '/'
                }
                const current_stock = this.currentStock.ingredients.find(ele => ele.ingredient_id === params.id)

                const col = this.joinedTable.find(ele => ele.ingredient_id === params.id)

                if (!col) {
                    return '/'
                }

                let enteredStock = col.stock

                if (!enteredStock || isNaN(enteredStock)) {
                    enteredStock = current_stock.total_stock
                }

                const newStock =  enteredStock - current_stock.total_stock

                const payload = {
                    stock: newStock,
                    packaging_unit: current_stock.packaging_unit,
                    ingredient_id: params.id
                }

                const convertedStock = this.getStock(payload)

                let color = '#F54F29'

                if (Number(newStock) > 0) {
                    color = '#FFD393'
                } else if (Number(newStock) === 0) {
                    color = '#9C9B7A'
                }


                return ` <div style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center">
                               <div style="padding: 4px 0;  width: 100%; line-height: 20px; background: ${color}; border-radius: 20px; text-align: center;">
                                 ${convertedStock}
                               </div>
                       </div>`
            },
            previousStep() {
                this.stepNum = 1
                this.columnDefs = this.firstStepColumns
            },
            async checkCurrentStock(skip) {
                if (this.stepNum === 1 && !skip) {
                    return
                }

                try {
                    const payload = {
                        date: this.addObject.timestamp
                    }

                    const response = await this.$http.post(`/api/client/v1/warehouses/stock/${this.addObject.warehouse_id}`, payload)
                    this.currentStock = response.data

                    this.stepNum = 2
                    this.columnDefs = this.secondStepColumns
                    this.$refs.stockEditingTable.refresh()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            viewReportModalDiff() {
                this.$refs.stockTakingReportModalDifferenceInStock.show(this.fileNameDiff, this.apiUrlDiff)
            },
            async viewReportModal() {
                await this.$refs.stockTakingReportModal.show(this.fileName, this.apiUrl)
            },
            async revertStockTakingData() {
                this.addObject.timestamp = this.stockTaking.timestamp
                this.addObject.warehouse_id = this.stockTaking.warehouse_id

                await this.updateTemporaryFileStockTakingData()
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        this.infoProvided = true
                    }
                })
            },
            async updateTemporaryFileStockTakingData() {
                if (this.compareData) return
              
                try {
                    const items = JSON.parse(JSON.stringify(this.addObject.items))

                    for (let i = 0; i < items.length; i++) {
                        items[i].stock = Math.trunc(items[i].stock)
                    }

                    const payload = {
                        'warehouse_id': this.addObject.warehouse_id,
                        'timestamp': this.addObject.timestamp,
                        items,
                        'edited':true
                    }

                    await this.$http.post(`/api/client/v1/stock_takings_temporary/${this.$route.params.id}`, payload)
                } catch (err) {
                    Sentry.captureMessage(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async discardChangesStockTaking() {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_temporary_msg'))
                    if (!confirmDelete) return

                    await this.$http.delete(`/api/client/v1/stock_takings_temporary/${this.$route.params.id}`)
                    this.finished = true
                    this.$printSuccess(this.$t('print.success.delete'))

                    await this.$router.push({name: 'stock_taking'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async deleteStockTaking() {

                const confirmDelete = await this.$confirmDialog(this.$t('material.stock_taking.confirm.delete_stock_taking'))
                this.deleteConfirmed = confirmDelete
                if (!confirmDelete) return

                try {

                    await this.$http.delete(`/api/client/v1/stock_takings/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.$router.push({name: 'stock_taking'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async finishedStockTaking(payload) {

                const confirmDelete = await this.$confirmDialog(this.$t('material.stock_taking.confirm.stock_taking'))
                if (!confirmDelete) return

                try {
                    this.allowConfirm = false
                    await this.$http.post(`/api/client/v1/stock_takings_temporary/permanent/${this.$route.params.id}`, payload)
                    this.allowConfirm = true

                    this.finished = true
                    this.$printSuccess(this.$t('print.success.add'))
                    this.$router.push({name: 'stock_taking'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = await this.$http.get('/api/client/v1/warehouses/')
                    const response3 = await this.$http.get('/api/client/v1/ingredients')
                    const response4 = await this.$http.get('/api/client/v1/ingredient_subcategories')

                    this.warehouses = response1.data ?? []
                    this.ingredients = response3.data ?? []

                    if (!this.editing) { // filtriramo iz tabele vn disabled ingredients
                        this.ingredients = this.ingredients.filter(ingredient => ingredient.disabled !== true)
                    }

                    this.ingredient_categories = response4.data ?? []

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.loaded = true
                    this.showLoader = false
                }
            },
            getIndexById(id) {
                return this.versions.findIndex(ele => ele.id === id)
            },
            async setSelection() {
                if (!this.compareData)  return this.$printError(this.$t('print.error.save_before_switch'))
                await this.openEditing(this.selected, this.selected, true)
            },
            async openEditing(data, stockTaking, editing) {

                this.showLoader = true
                this.editing = editing


                this.stockTaking = stockTaking

                if (this.stockTaking && this.editing) { // ne delamo nove inventure
                    this.addObject.items = data.items

                    this.apiUrl = `/api/reports/v1/printout/stock_taking/${  data.id  }`
                    this.fileName =  `Inventura_${data.stock_taking_code}.pdf`

                    this.apiUrlDiff = `/api/reports/v1/printout/stock_taking/difference_in_stock/${  data.id  }`
                    this.fileNameDiff =  `RazlikaVZalogiPriInventuri_${data.stock_taking_code}.pdf`
                } else { // delamo novo inventuro
                    this.stockTaking.items = []
                    this.addObject.items = []
                }

                if (data.warehouse_id !== this.$NilObjectId) {
                    await this.loadData()

                    this.addObject.warehouse_id = data.warehouse_id
                    this.addObject.timestamp = data.timestamp
                    this.addObject.items = data.items

                    this.infoProvided = true
                }
                this.showLoader = false
            },
            resetSum(id) {
                const col = this.ingredients.find(ele => ele.id === id)
                col.sum_stock = ''
                col.sum_quantity = ''
            },
            addQuantity(value, id) {
                const qty = parseFloat(value)

                if (isNaN(qty)) {
                    return
                }

                const item = this.ingredients.find(ele => ele.id === id)
                const col = this.addObject.items.find(ele => ele.ingredient_id === id)


                if (!col.quantity) {
                    col.quantity = qty
                    col.stock = qty * parseFloat(item.packaging)
                } else {
                    const currentValue = parseFloat(col.quantity)

                    if (isNaN(currentValue)) {
                        return
                    }

                    col.quantity = currentValue + qty
                    col.stock = (currentValue + qty) * parseFloat(item.packaging)
                }

                this.resetSum(id)
            },
            convertToDefaultStock(packaging, unit) {
                let type = null
                if (unit === 'mL' || unit === 'dL' || unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === 'g' || unit === 'dag' || unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return 0
                }

                const stock = Number(Number(packaging) * Number(type.factor)).toFixed(2).replace('.00', '')

                return Number(stock)
            },
            addStock(value, id) {
                const stock = parseFloat(value)

                if (isNaN(stock)) {
                    return
                }

                const item = this.ingredients.find(ele => ele.id === id)
                const col = this.addObject.items.find(ele => ele.ingredient_id === id)

                const stockDefault = this.convertToDefaultStock(stock, item.packaging_unit)

                if (!col.stock) {
                    col.stock = stockDefault
                    col.quantity = parseFloat(stockDefault / parseFloat(item.packaging)).toFixed(2).replace('.00', '')
                } else {
                    const currentValue = parseFloat(col.stock)

                    if (isNaN(currentValue)) {
                        return
                    }

                    col.stock = parseInt(currentValue + stockDefault)
                    col.quantity = parseFloat((currentValue + stockDefault) / parseFloat(item.packaging)).toFixed(2).replace('.00', '')
                }

                this.resetSum(id)
            },
            newStock(value, id) {
                const stock = parseFloat(value)

                if (isNaN(stock)) {
                    return
                }

                const item = this.ingredients.find(ele => ele.id === id)
                const col = this.addObject.items.find(ele => ele.ingredient_id === id)

                col.stock = parseInt(stock)
                col.quantity = parseFloat(stock / parseFloat(item.packaging)).toFixed(2)
            },
            newQuantity(value, id) {
                const qty = parseFloat(value)

                if (isNaN(qty)) {
                    return
                }

                const item = this.ingredients.find(ele => ele.id === id)
                const col = this.addObject.items.find(ele => ele.ingredient_id === id)

                col.quantity = qty
                col.stock = parseInt(qty * parseFloat(item.packaging))
            },
            rowValueChanged(value) {
                const col_name = value.column.colId
                const col_id = value.data.id
                const newValue = value.newValue

                if (newValue === undefined || newValue === null || newValue === '' || isNaN(newValue)) {
                    const index = this.addObject.items.findIndex(ele => ele.id === col_id)

                    if (index < 0) {
                        this.addObject.items.splice(index, 1)
                    }

                    value.api.refreshCells({
                        force: true,
                        suppressFlash: true
                    })
                    return
                }

                const col = this.addObject.items.find(ele => ele.ingredient_id === col_id)

                if (!col) {
                    this.addObject.items.push({
                        ingredient_id: col_id,
                        stock: 0,
                        packaging_unit: value.data.packaging_unit,
                        quantity: 0
                    })
                }

                if (col_name === 'quantity') {
                    this.newQuantity(newValue, col_id)
                } else if (col_name === 'stock') {
                    this.newStock(newValue, col_id)
                } else if (col_name === 'sum_stock') {
                    this.addStock(newValue, col_id)
                } else if (col_name === 'sum_quantity') {
                    this.addQuantity(newValue, col_id)
                }

                value.api.refreshCells({
                    force: true,
                    suppressFlash: true
                })

                this.updateTemporaryFileStockTakingData()
            },
            objectsDiffer(obj1, obj2) {
                return obj1.quantity !== obj2.quantity || obj1.stock !== obj2.stock || obj1.packaging_unit !== obj2.packaging_unit
            },
            checkField(data, fn) {
                const show =  fn(data)

                if (!this.stockTaking) {
                    return '/'
                }

                if (this.stockTaking.items.length === 0) { // ustvarjamo nov odpis
                    return `<div>${show}</div>`
                } else if (!this.stockTaking.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekta ni v originalnem arrayu
                    return `<div style="color: #2ecc71;">${show}</div>`
                } else if (this.stockTaking.items.some(obj => obj.ingredient_id === data.ingredient_id) && this.addObject.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekt obstaja v originalnem arrayju in v temporary

                    const temporaryItem = this.addObject.items.find(obj => obj.ingredient_id === data.ingredient_id)
                    const originalItem = this.stockTaking.items.find(obj => obj.ingredient_id === data.ingredient_id)

                    const original = fn(originalItem)
                    const temporary = fn(temporaryItem)

                    if (original !== temporary) { // vrednosti sta dejansko drugačni
                        return `<div>
                                    <span style="color: #e74c3c; text-decoration: line-through;">${original}</span>
                                    <span style="color: #2ecc71;">${temporary}</span>
                                </span>`
                    } else { // vrednosti sta enaki
                        return `<div>${original}</div>`
                    }
                } else if (this.stockTaking.items.some(obj => obj.ingredient_id === data.ingredient_id) && !this.addObject.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekt obstaja v original, ampak ne v temporary
                    const originalItem = this.stockTaking.items.find(obj => obj.ingredient_id === data.ingredient_id)
                    const original = fn(originalItem)

                    return `<div style="color: #e74c3c; text-decoration: line-through;">${original}</div>`
                }
            },
            async loadVersions() {
                try {
                    const res = await this.$http.get(`/api/client/v1/stock_takings_history/${this.$route.params.id}`)
                    this.versions = res.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                }
            }

        },
        mounted() {
            this.loadData()
            this.loadVersions()
            this.stockTakingId = this.$route.params.id
        },
        async beforeDestroy() {

            try {
                const items = JSON.parse(JSON.stringify(this.addObject.items))

                for (let i = 0; i < items.length; i++) {
                    items[i].stock = Math.trunc(items[i].stock)
                }

                const payload = {
                    'warehouse_id': this.addObject.warehouse_id,
                    'timestamp': this.addObject.timestamp,
                    items,
                    'edited':false
                }

                if (this.finished) return
                if (!this.deleteConfirmed) {
                    await this.$http.post(`/api/client/v1/stock_takings_temporary/${this.stockTakingId}`, payload)
                }
            } catch (err) {
                Sentry.captureMessage(err)
                this.$printError(this.$t('print.error.on_add'))
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.data-border{
  border: 2px solid #646464;
  //margin: 5px;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
  background: white;
  padding: 0 15px;
  font-weight: bold;
}

.dark-layout .border-text{
  background: #3e3e3e;
}

</style>
